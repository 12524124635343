import { navigationRailWidth, RailContextValue, RailTabKey } from "./NavigationRail";
import { createSignal, ParentProps, Show, Suspense } from "solid-js";
import SemiNavbar from "./SemiNavbar";
import SlidingMenu from "./SlidingMenu";
import CurrentOrganizationLogo from "../auth/CurrentOrganizationLogo";
import { parsedEnv } from "../../utils/parsedEnv";
import { Badge } from "../ui/components";
import { GenericSuspenseFallback } from "../ui/skeletons";
import { createMyRolesQuery } from "../../api/services/auth/queries";
import { Role } from "../../api/services/auth/interface";
import {
    defaultMenuProposal,
    MenuProposal,
    menuProposalStorage,
    NavigationDrawerDispatcher,
    NavigationRailDispatcher,
} from "./proposals/menuProposals";

const navigationDrawerWidth = "16rem";

export default function DesktopLayout(props: ParentProps) {
    const rolesQuery = createMyRolesQuery();
    const menuProposal = menuProposalStorage.useQuery(defaultMenuProposal);

    return (
        <Suspense fallback={<GenericSuspenseFallback />}>
            <Show when={rolesQuery.data}>
                {roles => (
                    <Show when={menuProposal.data}>
                        {proposal => (
                            <DesktopLayoutContent
                                roles={roles()}
                                showNavigationRail={
                                    roles().includes(Role.ADMIN) &&
                                    [MenuProposal.Pablo, MenuProposal.Crossover].includes(
                                        proposal(),
                                    )
                                }
                            >
                                {props.children}
                            </DesktopLayoutContent>
                        )}
                    </Show>
                )}
            </Show>
        </Suspense>
    );
}

function DesktopLayoutContent(props: ParentProps<{ showNavigationRail: boolean; roles: Role[] }>) {
    const [collapsed, setCollapsed] = createSignal(false);
    const toggleCollapsed = () => setCollapsed(x => !x);

    const bgEnvNavColor = parsedEnv.VITE_COLOR ?? "inherit";
    const badgeEnvText = parsedEnv.VITE_BADGE;
    const [activeRailTab, setActiveRailTab] = createSignal<RailTabKey>("home");
    const context: RailContextValue = {
        activeRailTab,
        setActiveRailTab,
        roles: () => props.roles,
    };

    return (
        <div
            class="grid h-screen grid-flow-col overflow-hidden bg-layout"
            style={{
                "grid-template-columns": props.showNavigationRail
                    ? `auto ${navigationDrawerWidth} 1fr`
                    : `${navigationDrawerWidth} 1fr`,
                "grid-template-rows": "auto 1fr",
                "border-top": `10px solid ${bgEnvNavColor}`,
            }}
        >
            <Show when={props.showNavigationRail}>
                <div class="bg-sidebar">
                    <Burger onClick={toggleCollapsed} />
                </div>
                <div class="bg-sidebar">
                    <NavigationRailDispatcher {...context} />
                </div>
            </Show>
            <div class={"flex h-full"}>
                <div class={props.showNavigationRail ? "self-center px-3" : "flex self-center"}>
                    {!props.showNavigationRail && <Burger onClick={toggleCollapsed} />}
                    <CurrentOrganizationLogo />
                    <Show when={badgeEnvText}>
                        <div class={"ml-2 self-center"}>
                            <Badge class={"!bg-dark-gray-700 text-white"}>{badgeEnvText}</Badge>
                        </div>
                    </Show>
                </div>
            </div>
            <div class="col-span-2 flex overflow-y-auto">
                <SlidingMenu collapsed={collapsed()} navigationDrawerWidth={navigationDrawerWidth}>
                    <div class="h-full overflow-y-auto pb-10">
                        <NavigationDrawerDispatcher {...context} />
                    </div>
                </SlidingMenu>
                <main class="h-full flex-1 overflow-y-auto rounded-ss-4 bg-white">
                    {props.children}
                </main>
            </div>
            <SemiNavbar />
        </div>
    );
}

function Burger(props: { onClick: () => void }) {
    const size = "2.75rem";

    return (
        <div class="flex justify-center" style={{ width: navigationRailWidth }}>
            <button
                onClick={() => props.onClick()}
                class="center-items my-3 rounded-full"
                style={{ width: size, height: size, "background-color": "#DBE1EB" }}
            >
                <i class="fas fa-bars text-md text-light-gray-700" />
            </button>
        </div>
    );
}
