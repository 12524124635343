import { enums } from "superstruct";
import JsonStorage from "../../../utils/JsonStorage";
import { RailContextValue } from "../NavigationRail";
import { Match, Show, Switch } from "solid-js";
import { PabloNavigationDrawer, PabloNavigationRail } from "./Pablo";
import { LuchoNavigationDrawer, LuchoNavigationRail } from "./Lucho";
import { RobinsonNavigationDrawer, RobinsonNavigationRail } from "./Robinson";
import { CrossoverNavigationDrawer, CrossoverNavigationRail } from "./Crossover";

export enum MenuProposal {
    Pablo = "Pablo",
    Lucho = "Lucho",
    Robinson = "Robinson",
    Crossover = "Crossover",
}

export const menuProposalStorage = JsonStorage(
    "menuProposal",
    enums(Object.values(MenuProposal) as MenuProposal[]),
);
export const defaultMenuProposal = MenuProposal.Crossover;

export function NavigationRailDispatcher(props: RailContextValue) {
    const query = menuProposalStorage.useQuery(defaultMenuProposal);

    return (
        <Show when={query.data}>
            <Switch>
                <Match when={query.data === MenuProposal.Pablo}>
                    <PabloNavigationRail {...props} />
                </Match>
                <Match when={query.data === MenuProposal.Lucho}>
                    <LuchoNavigationRail {...props} />
                </Match>
                <Match when={query.data === MenuProposal.Robinson}>
                    <RobinsonNavigationRail {...props} />
                </Match>
                <Match when={query.data === MenuProposal.Crossover}>
                    <CrossoverNavigationRail {...props} />
                </Match>
            </Switch>
        </Show>
    );
}

export function NavigationDrawerDispatcher(props: RailContextValue) {
    const query = menuProposalStorage.useQuery(defaultMenuProposal);

    return (
        <Show when={query.data}>
            <Switch>
                <Match when={query.data === MenuProposal.Pablo}>
                    <PabloNavigationDrawer {...props} />
                </Match>
                <Match when={query.data === MenuProposal.Lucho}>
                    <LuchoNavigationDrawer {...props} />
                </Match>
                <Match when={query.data === MenuProposal.Robinson}>
                    <RobinsonNavigationDrawer {...props} />
                </Match>
                <Match when={query.data === MenuProposal.Crossover}>
                    <CrossoverNavigationDrawer {...props} />
                </Match>
            </Switch>
        </Show>
    );
}
