import NavigationRail, { RailContextValue } from "../NavigationRail";
import { useLocale } from "../../i18n/context";
import TreeMenu from "../TreeMenu";
import { Role } from "../../../api/services/auth/interface";
import { PabloOrganizationTabs } from "./Pablo";
import { Show } from "solid-js";
import { useMenuItems } from "./Mock";

export function RobinsonNavigationRail(props: RailContextValue) {
    const [locale] = useLocale();
    const t = () => locale().menu.navigationRailTabs;

    return (
        <NavigationRail {...props}>
            <NavigationRail.Tab key="home" title={t().home} icon="fas fa-home" />
        </NavigationRail>
    );
}

export function RobinsonNavigationDrawer(props: RailContextValue) {
    const items = useMenuItems();

    return (
        <nav>
            <TreeMenu>
                <Show when={props.roles().includes(Role.ADMIN)}>
                    <TreeMenu.Label
                        title="Homes"
                        href={
                            props.roles().includes(Role.ADMIN)
                                ? "/lorem-ipsum/Admin_Homes/homes-admin"
                                : undefined
                        }
                    />
                </Show>
                {items.home()}
                <TreeMenu.Separator />
                <TreeMenu.Label
                    title="Canales de comunicación"
                    href={
                        props.roles().includes(Role.ADMIN)
                            ? "/lorem-ipsum/Admin_Canales/channels"
                            : undefined
                    }
                />
                <TreeMenu.Tab title="Supermercado" icon="fas fa-shopping-cart" />
                <TreeMenu.Tab title="Capacítate aquí" icon="fas fa-bullhorn" />
                <TreeMenu.Label
                    title="Procesos"
                    href={props.roles().includes(Role.ADMIN) ? "/workflows" : undefined}
                />
                <TreeMenu.Tab title="Procesos mineros" icon="fas fa-project-diagram">
                    {/*<TreeMenu.Tab title="Ejecutar nuevo" size="sm" />*/}
                    {/*<TreeMenu.Tab title="Lista de ejecuciones" size="sm" />*/}
                </TreeMenu.Tab>
                <TreeMenu.Tab title="Workflow 2" icon="fas fa-project-diagram">
                    {/*<TreeMenu.Tab title="Ejecutar nuevo" size="sm" />*/}
                    {/*<TreeMenu.Tab title="Lista de ejecuciones" size="sm" />*/}
                </TreeMenu.Tab>
                <TreeMenu.Separator />
                <TreeMenu.Tab title="Reportes" icon="fas fa-chart-pie" />
                <TreeMenu.Tab title="Actividades" href="/activities" icon="fas fa-tasks" />
                <TreeMenu.Tab
                    title="Checklist"
                    icon="fas fa-check-square"
                    href="/checklist-administration"
                >
                    {/*<TreeMenu.Tab title="Ejecutar nuevo" size="sm" />*/}
                    {/*<TreeMenu.Tab*/}
                    {/*    title="Lista de ejecuciones"*/}
                    {/*    href="/checklists/executions"*/}
                    {/*    size="sm"*/}
                    {/*/>*/}
                </TreeMenu.Tab>
                <TreeMenu.Tab title="Inventario" icon="fas fa-box" />
                <TreeMenu.Tab title="Documentos" icon="fas fa-file" />
                <Show when={props.roles().includes(Role.ADMIN)}>
                    <TreeMenu.Label title="Organización" />
                    <PabloOrganizationTabs />
                </Show>
            </TreeMenu>
        </nav>
    );
}
