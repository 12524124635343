import NavigationRail, { RailContextValue } from "../NavigationRail";
import { useLocale } from "../../i18n/context";
import TreeMenu from "../TreeMenu";
import { Match, Switch } from "solid-js";

export function PabloNavigationRail(props: RailContextValue) {
    const [locale] = useLocale();
    const t = () => locale().menu.navigationRailTabs;

    return (
        <NavigationRail {...props}>
            <NavigationRail.Tab key="home" title={t().home} icon="fas fa-home" />
            <NavigationRail.Tab key="tools" title={t().tools} icon="fas fa-cubes" />
            <NavigationRail.Tab
                key="organization"
                title={t().organization}
                icon="fas fa-user-shield"
            />
            <NavigationRail.Tab key="advanced" title={t().advanced} icon="fas fa-cog" />
        </NavigationRail>
    );
}

export function PabloNavigationDrawer(props: RailContextValue) {
    return (
        <Switch>
            <Match when={props.activeRailTab() === "home"}>
                <nav class="py-3">
                    <TreeMenu>
                        <TreeMenu.Tab
                            title="Operaciones"
                            href="/home"
                            icon="fas fa-building"
                            size="lg"
                        />
                        <TreeMenu.Tab
                            title="Grandes superficies"
                            icon="fas fa-shopping-cart"
                            size="lg"
                        />
                        <TreeMenu.Separator />
                        <TreeMenu.Tab title="Supermercado" icon="fas fa-shopping-cart" />
                        <TreeMenu.Tab title="Capacítate aquí" icon="fas fa-bullhorn" />
                        <TreeMenu.Tab title="Procesos mineros" icon="fas fa-project-diagram">
                            <TreeMenu.Tab title="Ejecutar nuevo" size="sm" />
                            <TreeMenu.Tab title="Lista de ejecuciones" size="sm" />
                        </TreeMenu.Tab>
                        <TreeMenu.Tab title="Mis reportes" icon="fas fa-chart-pie" />
                        <TreeMenu.Tab title="Actividades" href="/activities" icon="fas fa-list" />
                        <TreeMenu.Tab title="Checklist" icon="fas fa-project-diagram">
                            <TreeMenu.Tab title="Ejecutar nuevo" size="sm" />
                            <TreeMenu.Tab
                                title="Lista de ejecuciones"
                                href="/checklists/executions"
                                size="sm"
                            />
                        </TreeMenu.Tab>
                        <TreeMenu.Tab title="Inventario" icon="fas fa-box" />
                    </TreeMenu>
                </nav>
            </Match>
            <Match when={props.activeRailTab() === "tools"}>
                <nav class="pl-6 pt-5">
                    <TreeMenu>
                        <PabloToolTabs />
                    </TreeMenu>
                </nav>
            </Match>
            <Match when={props.activeRailTab() === "organization"}>
                <nav class="py-3">
                    <TreeMenu>
                        <PabloOrganizationTabs />
                    </TreeMenu>
                </nav>
            </Match>
            <Match when={props.activeRailTab() === "advanced"}>
                <nav class="pl-6 pt-5">
                    <TreeMenu>
                        <PabloAdvancedTabs />
                    </TreeMenu>
                </nav>
            </Match>
        </Switch>
    );
}

export function PabloToolTabs() {
    return (
        <>
            <TreeMenu.Tab title="Supermercado" icon="fas fa-shopping-cart" />
            <TreeMenu.Tab title="Capacítate aquí" icon="fas fa-bullhorn" />
            <TreeMenu.Tab title="Procesos mineros" icon="fas fa-project-diagram" />
            <TreeMenu.Tab title="Reportes" icon="fas fa-chart-pie" />
            <TreeMenu.Tab title="Actividades" href="/activities" icon="fas fa-list" />
            <TreeMenu.Tab
                title="Checklist"
                icon="fas fa-project-diagram"
                href="/checklist-administration"
            />
            <TreeMenu.Tab title="Inventario" icon="fas fa-box" />
            <TreeMenu.Tab title="Documentos" icon="fas fa-file" />
        </>
    );
}

export function PabloOrganizationTabs() {
    return (
        <>
            <TreeMenu.Tab title="Personas" icon="fas fa-users" href="/people" />
            <TreeMenu.Tab title="Organización" icon="fas fa-sitemap" href="/organization" />
            <TreeMenu.Tab title="Roles y permisos" icon="fas fa-lock" />
            <TreeMenu.Tab title="Organización del Negocio" icon="fas fa-globe">
                <TreeMenu.Tab size="sm" title="Líneas de negocio" />
                <TreeMenu.Tab size="sm" title="Áreas de negocio" />
            </TreeMenu.Tab>
            <TreeMenu.Tab title="Recintos" icon="fas fa-building">
                <TreeMenu.Tab size="sm" title="Lista de recintos" />
                <TreeMenu.Tab size="sm" title="Tipo de inmueble" />
                <TreeMenu.Tab size="sm" title="Marcas" />
                <TreeMenu.Tab size="sm" title="Tiendas" />
            </TreeMenu.Tab>
        </>
    );
}

export function PabloAdvancedTabs() {
    return (
        <>
            <TreeMenu.Tab title="Páginas de inicio" icon="fas fa-shopping-cart" />
            <TreeMenu.Tab title="Canales de comunicados" icon="fas fa-comment" />
            <TreeMenu.Tab title="Tipos de workflows" icon="fas fa-project-diagram" />
        </>
    );
}
