import {
    any,
    array,
    boolean,
    enums,
    Infer,
    integer,
    intersection,
    is,
    literal,
    nullable,
    optional,
    record,
    refine,
    string,
    type,
    union,
} from "superstruct";
import { InputUnion } from "./interfaces/inputs/BaseInput";
import { InputTypeEnum } from "./interfaces/InputTypeEnum";
import { FormResponse } from "../workflow/interface";
import { sDateTimeString } from "../../utils";
import { FormValues, isBlank } from "../../../modules/forms/state";
import { documentManagerClient } from "../../clients/documentManager";

/** A form whose fields come from the backend, as they are configured by an
 * admin through the Form Builder.
 *
 * The backend has a couple of form objects, e.g. WorkflowForm, ChecklistForm,
 * each with different properties. Here are the common properties between them,
 * so the Form Builder can work on this generic version of forms.
 *
 * @see WorkflowForm
 * @see ChecklistForm */
export interface DynamicForm {
    /** The id of the WorkflowForm / ChecklistForm / etc. on the backend. */
    id: string;
    /** The fields in the order they should appear on the UI, assuming a
     * single column layout. */
    fields: InputUnion[];
}

export function sBaseFormJson() {
    return type({
        id: string(),
        description: sV3FormDescription(),
    });
}

export function sV3FormDescription() {
    return type({
        fields: array(sV3Field()),
    });
}

export type V3Field = Infer<ReturnType<typeof sV3Field>>;

export function sV3Field() {
    return union([
        intersection([
            sV3BaseInput(),
            type({
                type: enums([
                    InputTypeEnum.text,
                    InputTypeEnum.boolean,
                    InputTypeEnum.datetime,
                    InputTypeEnum.integer,
                    InputTypeEnum.time,
                    InputTypeEnum.float,
                    InputTypeEnum.decimal,
                    InputTypeEnum.voice_message,
                    InputTypeEnum.code,
                    InputTypeEnum.date_range,
                    InputTypeEnum.num_range,
                    InputTypeEnum.gps,
                ]),
            }),
        ]),
        intersection([
            sV3BaseInput(),
            type({
                type: literal(InputTypeEnum.files),
                max_files: integer(),
            }),
        ]),
        intersection([
            sV3BaseInput(),
            type({
                type: literal(InputTypeEnum.multiple_choice),
                choices: array(sV3Choice()),
            }),
        ]),
        intersection([
            sV3BaseInput(),
            type({
                type: literal(InputTypeEnum.date),
                format: optional(
                    refine(string(), "Python strftime", format => {
                        if (format !== "%Y-%m-%d")
                            return "Format different from default not supported by frontend.";
                        return true;
                    }),
                ),
            }),
        ]),
        intersection([
            sV3BaseInput(),
            type({
                type: literal(InputTypeEnum.checkboxes),
                choices: array(sV3Choice()),
                min_answers: integer(),
                max_answers: integer(),
            }),
        ]),
        intersection([
            sV3BaseInput(),
            type({
                type: literal(InputTypeEnum.value_in_num_range),
                start: integer(),
                stop: integer(),
                step: integer(),
            }),
        ]),
        intersection([
            sV3BaseInput(),
            type({
                type: literal(InputTypeEnum.venue),
            }),
        ]),
        intersection([
            sV3BaseInput(),
            type({
                type: literal(InputTypeEnum.stars),
                max: integer(),
                labels: array(string()),
            }),
        ]),
    ]);
}

function sV3BaseInput() {
    return type({
        id: union([integer(), string()]),
        name: string(),
        style_class: optional(nullable(string())),
        required: boolean(),
        skippable: boolean(),
        label: string(),
        placeholder: optional(nullable(string())),
        readonly: optional(boolean()),
        disabled: optional(boolean()),
        validators: array(string()),
        show_if: optional(nullable(string())),
        description: optional(nullable(string())),
    });
}

function sV3Choice() {
    return type({
        choice: union([string(), integer()]),
        image: nullable(string()),
    });
}

export function deserializeField(payload: V3Field): InputUnion {
    return {
        ...payload,
        id: payload.id.toString(),
        placeholder: payload.placeholder || "",
        description: payload.description || null,
    };
}

export function serializeField(field: InputUnion): V3Field {
    return {
        ...field,
        id: Number.parseInt(field.id),
    };
}

type V3FormResponse = Infer<ReturnType<typeof sV3FormResponse>>;

export function sV3FormResponse() {
    return type({
        // id: integer(), // we don't care
        created_at: sDateTimeString(),
        updated_at: sDateTimeString(),
        response: optional(nullable(sBackendFormValues())),
        // workflow_form: string(), // we don't care
        user: integer(),
        // execution: integer(), // we don't care
    });
}

export function deserializeFormResponse(payload: V3FormResponse): FormResponse {
    return {
        createdAt: payload.created_at,
        updatedAt: payload.updated_at,
        formValues: payload.response ?? null,
        userId: payload.user.toString(),
    };
}

export type BackendFormValues = Infer<ReturnType<typeof sBackendFormValues>>;

export function sBackendFormValues() {
    return record(string(), any());
}

export async function serializeFormValues(formValues: FormValues): Promise<BackendFormValues> {
    const ans: FormValues = {};
    for (const [fieldName, value] of Object.entries(formValues)) {
        if (!isBlank(value))
            ans[fieldName] = is(value, type({ temporary_upload: string() }))
                ? await documentManagerClient
                      .post(`/filepond/${value.temporary_upload}/store`)
                      .sendJson({ temporary_upload: value.temporary_upload })
                      .receive(type({ upload_id: string() }))
                      .then(payload => [
                          `${documentManagerClient.urlPrefix}/filepond/${payload.upload_id}/download`,
                      ])
                : value;
    }
    return ans;
}
