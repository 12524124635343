import NavigationRail, { RailContextValue } from "../NavigationRail";
import { useLocale } from "../../i18n/context";
import TreeMenu from "../TreeMenu";
import { Accessor, For, Match, Show, Suspense, Switch } from "solid-js";
import { PabloOrganizationTabs } from "./Pablo";
import { Role } from "../../../api/services/auth/interface";
import { createMyWorkflowTypesQuery } from "../../../api/services/workflow/workflow-types/queries";
import { MyWorkflowType } from "../../../api/services/workflow/workflow-types/interface";
import { GenericSuspenseFallback } from "../../ui/skeletons";
import { parsedEnv } from "../../../utils/parsedEnv";

export function CrossoverNavigationRail(props: RailContextValue) {
    const [locale] = useLocale();
    const t = () => locale().menu.navigationRailTabs;

    return (
        <NavigationRail {...props}>
            <NavigationRail.Tab key="home" title={t().home} icon="fas fa-home" />
            {/*<NavigationRail.Tab key="tools" title={t().tools} icon="fas fa-cubes" />*/}
            <NavigationRail.Tab
                key="organization"
                title={t().organization}
                icon="fas fa-user-shield"
            />
            {/*<NavigationRail.Tab key="advanced" title={t().advanced} icon="fas fa-cog" />*/}
        </NavigationRail>
    );
}

export function CrossoverNavigationDrawer(props: RailContextValue) {
    // const workflowMenus = [];
    const myWorkflowTypesQuery = createMyWorkflowTypesQuery();
    return (
        <Suspense fallback={<GenericSuspenseFallback />}>
            <Switch>
                <Match when={props.activeRailTab() === "home"}>
                    <nav class="py-3">
                        <TreeMenu>
                            <Show when={props.roles().includes(Role.ADMIN)}>
                                <TreeMenu.Label
                                    title="Homes"
                                    href="/lorem-ipsum/Admin_Homes/homes-admin"
                                />
                            </Show>
                            <TreeMenu.Tab
                                title="Operaciones"
                                href="/home"
                                icon="fas fa-building"
                                size="lg"
                            />
                            <TreeMenu.Tab
                                title="Grandes superficies"
                                icon="fas fa-shopping-cart"
                                size="lg"
                            />
                            <TreeMenu.Separator />
                            <Show when={props.roles().includes(Role.ADMIN)}>
                                <TreeMenu.Label
                                    title="Canales de comunicación"
                                    href="/lorem-ipsum/Admin_Canales/channels"
                                />
                            </Show>
                            <TreeMenu.Tab title="Supermercado" icon="fas fa-shopping-cart" />
                            <TreeMenu.Tab title="Capacítate aquí" icon="fas fa-bullhorn" />
                            <Show when={props.roles().includes(Role.ADMIN)}>
                                <TreeMenu.Label title="Procesos" href="/workflows" />
                            </Show>

                            <Show when={myWorkflowTypesQuery.data}>
                                {wtTypes => (
                                    <For each={wtTypes()}>
                                        {wfType => <WFMenu roles={props.roles} wfType={wfType} />}
                                    </For>
                                )}
                            </Show>
                            <Show when={props.roles().includes(Role.ADMIN)}>
                                <TreeMenu.Label title="Otros" />
                            </Show>
                            <TreeMenu.Tab title="Mis reportes" icon="fas fa-chart-pie" />
                            <TreeMenu.Tab
                                title="Actividades"
                                href="/activities"
                                icon="fas fa-list"
                            />
                            <TreeMenu.Tab title="Checklists" icon="fas fa-project-diagram">
                                <TreeMenu.Tab title="Ejecutar nuevo..." size="sm" />
                                <TreeMenu.Tab
                                    title="Lista de ejecuciones"
                                    size="sm"
                                    href="/checklists/executions"
                                />
                                <Show when={props.roles().includes(Role.ADMIN)}>
                                    <TreeMenu.Tab
                                        title="Administrar"
                                        size="sm"
                                        href="/checklist-administration"
                                    />
                                </Show>
                            </TreeMenu.Tab>
                            <TreeMenu.Tab title="Inventario" icon="fas fa-box" />
                            <TreeMenu.Tab title="Documentos" icon="fas fa-file" />
                        </TreeMenu>
                    </nav>
                </Match>
                <Match when={props.activeRailTab() === "organization"}>
                    <nav class="py-3">
                        <TreeMenu>
                            <PabloOrganizationTabs />
                        </TreeMenu>
                    </nav>
                </Match>
            </Switch>
        </Suspense>
    );
}

function WFMenu(props: { roles: Accessor<Role[]>; wfType: MyWorkflowType }) {
    return (
        <TreeMenu.Tab title={props.wfType.name} icon="fas fa-project-diagram">
            <Show when={parsedEnv.VITE_FEATURE_WORKFLOWS_EXECUTE_FROM_MENU}>
                <TreeMenu.Tab
                    title={`Ejecutar nuevo`}
                    size="sm"
                    href={`/workflows/${props.wfType.id}/execute`}
                />
            </Show>
            <TreeMenu.Tab
                title="Lista de ejecuciones"
                size="sm"
                href={`/workflows/${props.wfType.id}/executions`}
            />
            <Show when={props.roles().includes(Role.ADMIN) && props.wfType.is_admin}>
                <TreeMenu.Tab
                    title="Administrar"
                    size="sm"
                    href={`/workflows/${props.wfType.id}/administration`}
                />
            </Show>
        </TreeMenu.Tab>
    );
}
