import { sDateTimeString, ServiceQuery } from "../../utils";
import { FormResponse } from "../workflow/interface";
import {
    array,
    enums,
    Infer,
    integer,
    intersection,
    nullable,
    number,
    optional,
    string,
    Struct,
    type,
} from "superstruct";
import type { Locale } from "../../../modules/i18n/Locale";
import { parsedEnv } from "../../../utils/parsedEnv";
import {
    BackendFormValues,
    deserializeField,
    DynamicForm,
    sBaseFormJson,
} from "../formbuilder/DynamicForm";
import { FormValues } from "../../../modules/forms/state";
import { ActivitySet, VTodo } from "../../../modules/Activities/ActivitySet";

export interface TaskManagerService {
    /** Calendar corresponding to "My Activities". */
    retrieveMyCalendar: ServiceQuery<[], Calendar>;

    retrieveActivityDetail: ServiceQuery<[activityId: string, recurrenceId: string], ActivityItem>;
    getOrCreateRecurrenceActivity: ServiceQuery<
        [activityId: string, recurrenceId: string],
        RecurrenceActivity
    >;
    retrieveActivities: ServiceQuery<[], Calendar>;
    createActivity(activity: CreateActivityPayload): Promise<{ id: string }>; //POST activities/
    createActivityTask(payload: CreateActivityTaskPayload): Promise<{ id: string }>; //POST activity-tasks/
    activateActivity(activityId: string): void; //PUT/PATCH activities/{activityId}/activate/
    getActivityTasks: ServiceQuery<[recurrenceActivityId: string], ActivityTask[]>; //GET activity-tasks/
    createRecurrenceActivity(
        payload: CreateRecurrenceActivityPayload,
    ): Promise<Omit<RecurrenceActivity, "status" | "completed_at">>; //POST en recurrence-activities/

    // #region Activity execution
    startEvent(payload: RecurrenceTaskStartWorkflowPayload): void; //recurrence-tasks/start-event/
    messageStartEvent(payload: MessageStartEventPayload): void; //recurrence-tasks/message-start-event/
    checklistExecution(payload: ChecklistExecutionStartEventPayload): void; //recurrence-tasks/checklist-execution/
    formResponse(params: CreateActivityFormResponseParams): Promise<void>; // recurrence-tasks/form-response/
    retrieveForm: ServiceQuery<[formId: string], ActivityForm>;
    getRecurrenceTasks: ServiceQuery<[recurrenceActivityId: string], RecurrenceTask[]>;
    retrieveFormResponse: ServiceQuery<[formResponseId: string], FormResponse>;
    // #endregion

    createRecurrenceTaskWFFormResponse(
        payload: RecurrenceFormResponsePayload,
    ): Promise<RecurrenceFormResponse>;

    completeRecurrenceActivity(recurrenceActivityId: string): void;
    activitiesByExecution: ServiceQuery<[executionId: string], ActivityByExecution[]>;
}
export type ActivityByExecution = {
    id: string;
    name: string;
    is_active?: boolean;
    user_task: string;
    recurrence_id: string;
    duration: string;
};
export type RecurrenceFormResponsePayload = {
    recurrence_activity: string;
    task: string;
    response: FormValues;
};
type RecurrenceFormResponse = {
    recurrence_activity: string;
    task: string;
};
/** An abstract collection of activity recurrences. */
export interface Calendar {
    /** Calendars may have infinite activities because of recurrences.
     * So this returns activities within a given date range.
     * @param startDate - Inclusive start of date range.
     * @param endDate - Inclusive end of date range.
     * @example
     * // Activities for today and tomorrow
     * calendar.getActivities(
     *     Temporal.Now.plainDateIso(),
     *     Temporal.Now.plainDateIso().add({ days: 1 }),
     * )
     */
    getActivities(startDate: Temporal.PlainDate, endDate: Temporal.PlainDate): ActivityItem[];
    activityRecurrences: ActivitySet[];
}

type ActivityAttrs = {
    id: string;
    title: string;
    priority: Priority;
    startDate: Temporal.ZonedDateTime;
    dueDate: Temporal.ZonedDateTime;
    venue?: { name: string } | null;
    status?: ICALStatus;
    executionAudience?: string;
};

type ICALStatus = "NEEDS-ACTION" | "COMPLETED";

export class ActivityItem implements ActivityAttrs {
    id: string;
    title: string;
    priority: Priority;
    startDate: Temporal.ZonedDateTime;
    dueDate: Temporal.ZonedDateTime;
    venue?: { name: string } | null;
    status?: ICALStatus;
    executionAudience?: string;

    constructor(attrs: ActivityAttrs) {
        this.id = attrs.id;
        this.title = attrs.title;
        this.priority = attrs.priority;
        this.startDate = attrs.startDate;
        this.dueDate = attrs.dueDate;
        this.venue = attrs.venue;
        this.status = attrs.status;
        this.executionAudience = attrs.executionAudience;
    }

    static fromVTodo(
        vTodo: VTodo,
        startDate: Temporal.ZonedDateTime,
        name: string,
        executionAudience?: string,
    ): ActivityItem {
        return new ActivityItem({
            id: vTodo.uid,
            title: name,
            priority: Priority.Medium,
            startDate,
            dueDate: startDate.add(vTodo.duration),
            venue:
                parsedEnv.MODE === "development" && startDate.day % 2 === 0
                    ? { name: "Parque hardcodeado" }
                    : undefined,
            status: vTodo.status,
            executionAudience,
        });
    }

    static compare(a: ActivityItem, b: ActivityItem): number {
        return (
            // first by priority
            Priority.compare(a.priority, b.priority) ||
            // then by due date
            Temporal.ZonedDateTime.compare(a.dueDate, b.dueDate)
        );
    }

    // navigate(this.href) should go to the activity detail
    get href() {
        return `/activities/${this.id}/${this.recurrenceId}`;
    }

    get recurrenceId(): string {
        return this.startDate.toString().split("[")[0];
    }

    getStatus(nowZdt: Temporal.ZonedDateTime): ActivityStatus {
        if (this.status === "NEEDS-ACTION") return ActivityStatus.COMPLETED;
        if (Temporal.ZonedDateTime.compare(nowZdt, this.dueDate) > 0) return ActivityStatus.EXPIRED;
        if (Temporal.ZonedDateTime.compare(nowZdt, this.startDate) < 0)
            return ActivityStatus.SCHEDULED;
        return ActivityStatus.ACTIVE;
    }
}

export enum ActivityStatus {
    SCHEDULED = "SCHEDULED",
    ACTIVE = "ACTIVE",
    COMPLETED = "COMPLETED",
    EXPIRED = "EXPIRED",
}

/** Maps AIM priorities to [iCalendar priorities](https://icalendar.org/iCalendar-RFC-5545/3-8-1-9-priority.html) */
export enum Priority {
    Reminder = 0,
    ThreeUpArrows = 2,
    TwoUpArrows = 3,
    OneUpArrow = 4,
    Medium = 5,
    OneDownArrow = 6,
    TwoDownArrows = 7,
    ThreeDownArrows = 8,
}

// https://stackoverflow.com/questions/28150739/add-functions-to-an-enum
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace Priority {
    /** @param value - https://icalendar.org/iCalendar-RFC-5545/3-8-1-9-priority.html */
    export function fromICAL(value: number): Priority {
        if (
            !(Number.isInteger(value) && 0 <= value && value <= 9) &&
            parsedEnv.MODE !== "production"
        )
            throw new Error(`${value} is not a valid iCalendar priority`);

        // If a priority is removed from the mapping, migrate it silently to the nearest available value
        const enumValues = getEnumValues();
        if (!enumValues.includes(value)) value = enumValues.findLast(p => p <= value)!;

        return value;
    }

    /** Defined such that `activities.map(a => a.priority).sort(Priority.compare)` sort them by priority. */
    export function compare(a: Priority, b: Priority): number {
        return a - b;
    }

    /** Returns an array of options for SelectField/RadioField/etc. */
    export function getOptions(locale: Locale): [value: number, label: string][] {
        return getEnumValues()
            .filter(p => p !== Priority.Reminder)
            .map(p => [p, locale.activities.priorities[p]]);
    }

    /** https://stackoverflow.com/questions/28150739/add-functions-to-an-enum#comment97904258_28151986 */
    export function getEnumValues(): Priority[] {
        return Object.values(Priority).filter(Number.isInteger) as Priority[];
    }
}

export type ActivityTask = {
    id: string;
    activity: string;
    content_type_model: string;
    object_id: string;
};

export function sCreateActivityPayload() {
    return type({
        name: string(),
        description: string(),
        priority: number(),
        categories: array(string()),
        start_at: string(),
        rrule: string(),
        duration: string(),
        execution_audience: string(),
    });
}

export type CreateActivityPayload = Infer<ReturnType<typeof sCreateActivityPayload>>;

export function sCreateActivityTaskPayload() {
    return type({
        activity: string(),
        object_id: string(),
        content_type: string(),
    });
}

export type CreateActivityTaskPayload = Infer<ReturnType<typeof sCreateActivityTaskPayload>>;

type CreateRecurrenceActivityPayload = {
    activity: string;
    recurrence_id: string;
};

type RecurrenceTaskStartWorkflowPayload = {
    start_event: string;
    workflow: string;
    recurrence_activity: string;
    task: string;
};

type MessageStartEventPayload = RecurrenceTaskStartWorkflowPayload & {
    data: string;
};

type ChecklistExecutionStartEventPayload = {
    recurrence_activity: string;
    task: string;
    object_id: string;
};

export type CreateActivityFormResponseParams = {
    recurrence_activity: string;
    task: string;
    form: string;
    response: BackendFormValues;
};

export function sRecurrenceActivity() {
    return type({
        id: string(),
        activity: string(),
        recurrence_id: string(),
        status: enums(["NEEDS-ACTION", "COMPLETED"]),
        completed_at: nullable(sDateTimeString()),
    });
}
export type RecurrenceActivity = Infer<ReturnType<typeof sRecurrenceActivity>>;

// #region ActivityForm

export interface ActivityForm extends DynamicForm {}

export type ActivityFormJson = Infer<ReturnType<typeof sActivityFormJson>>;

export function sActivityFormJson() {
    return intersection([sBaseFormJson(), type({})]);
}

export function deserializeActivityForm(payload: ActivityFormJson): ActivityForm {
    return {
        id: payload.id,
        fields: payload.description.fields.map(deserializeField),
    };
}

// #endregion

export type RecurrenceTask = {
    id: string;
    content_type_model: string;
    user_name: string;
    created_at?: string | null;
    updated_at?: string | null;
    completed_at?: string | null;
    object_id: string;
    recurrence_activity: string;
    task: string;
    content_type: number;
};
export function sRecurrenceTask(): Struct<RecurrenceTask> {
    return type({
        id: string(),
        content_type_model: string(),
        user_name: string(),
        created_at: optional(nullable(sDateTimeString())),
        updated_at: optional(nullable(sDateTimeString())),
        completed_at: optional(nullable(sDateTimeString())),
        object_id: string(),
        recurrence_activity: string(),
        task: string(),
        content_type: integer(),
    });
}
