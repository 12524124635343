import NavigationRail, { RailContextValue } from "../NavigationRail";
import { useLocale } from "../../i18n/context";
import TreeMenu from "../TreeMenu";
import { PabloAdvancedTabs, PabloOrganizationTabs, PabloToolTabs } from "./Pablo";
import { Show } from "solid-js";
import { Role } from "../../../api/services/auth/interface";

export function LuchoNavigationRail(props: RailContextValue) {
    const [locale] = useLocale();
    const t = () => locale().menu.navigationRailTabs;

    return (
        <NavigationRail {...props}>
            <NavigationRail.Tab key="home" title={t().home} icon="fas fa-home" />
        </NavigationRail>
    );
}

export function LuchoNavigationDrawer(props: RailContextValue) {
    return (
        <nav>
            <TreeMenu>
                <TreeMenu.Label title="Vistas" />
                <TreeMenu.Tab title="de jefe" icon="fas fa-user-tie" />
                <TreeMenu.Tab title="de jefe del jefe" icon="fas fa-user-friends" />
                <TreeMenu.Tab title="de rata de cola pelada" icon="fab fa-affiliatetheme" />
                <Show when={props.roles().includes(Role.ADMIN)}>
                    <TreeMenu.Label title="Administración" />
                    <PabloToolTabs />
                    <PabloOrganizationTabs />
                    <PabloAdvancedTabs />
                </Show>
                <TreeMenu.Label title="Canales de comunicación" />
                <TreeMenu.Tab title="Descuentos" icon="fas fa-percent" />
                <TreeMenu.Tab title="Información corporativa" icon="fas fa-info-circle" />
                <TreeMenu.Tab title="Lo que deberías saber de tu trabajo" icon="fas fa-bullhorn" />
                <TreeMenu.Label title="Procesos" />
                <TreeMenu.Tab title="Laborales" icon="fas fa-folder" />
                <TreeMenu.Tab title="Mejoras" icon="fas fa-folder" />
                <TreeMenu.Tab title="Incidentes" icon="fas fa-folder" />
                <TreeMenu.Tab title="Urgencias" icon="fas fa-folder" />
                <TreeMenu.Tab title="pedir vacaciones" icon="fas fa-file" />
                <TreeMenu.Label title="Reportes" />
                <TreeMenu.Tab title="Reporte 1" icon="fas fa-chart-pie" />
                <TreeMenu.Tab title="Reporte 2" icon="fas fa-chart-pie" />
                <TreeMenu.Label title="Actividades" />
                <TreeMenu.Tab title="Actividades" icon="fas fa-clipboard" />
                <TreeMenu.Label title="Checklists" />
                <TreeMenu.Tab title="Checklist 1" icon="fas fa-list" />
                <TreeMenu.Tab title="Checklist 2" icon="fas fa-list" />
                <TreeMenu.Tab title="Categoría Checklist" icon="fas fa-folder" />
            </TreeMenu>
        </nav>
    );
}
