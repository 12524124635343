import {
    createForm,
    FormController,
    FormStateContext,
    FormValues,
    OnSubmit,
    OptionalityIndicator,
    useFormState,
} from "./state";
import { batch, createEffect, JSX, onCleanup, onMount, ParentProps, untrack } from "solid-js";
import _ from "lodash";
import { cloneFormValues } from "./utils";

export type FormWrapperProps<TFormValues extends FormValues> = ParentProps<{
    onSubmit?: OnSubmit<TFormValues>;
    class?: string;
    style?: JSX.CSSProperties | string;
    staticForm?: FormController<TFormValues>;

    /** If provided, an object will the initial value of `form.values`.
     *
     * @remarks
     * `defaultValues` may have additional values not referenced by any field,
     * see {@link FormController.values}.
     *
     * This overrides any `defaultValue` defined in individual fields.
     */
    defaultValues?: TFormValues;

    onChange?: (formValues: TFormValues) => void;

    /** Called when the form unmounts, before its fields unmount.
     * Useful for creating a form that is submitted when a panel is closed.
     */
    onCleanup?: (formValues: TFormValues) => void;
    readOnly?: boolean;
    optionalityIndicator?: OptionalityIndicator;
    fieldWrapperClass?: string;
}>;

export function FormWrapper<TFormValues extends FormValues>(props: FormWrapperProps<TFormValues>) {
    // staticForm
    const form = props.staticForm ?? createForm();

    // Populate form with defaultValues
    onMount(() => {
        batch(() => {
            if (props.defaultValues) {
                // use cloneFormValues because https://runkit.com/robin40/6646c41776eeb40008d2dbf5
                form.state.setValueStore(cloneFormValues(props.defaultValues));
                form.state.updateFormValidation();
            }
        });
    });

    // onChange
    const onChange = untrack(() => props.onChange);
    if (onChange) {
        createEffect(first => {
            // formValues is a store, so we clone the object to read all its signals
            const formValues = cloneFormValues(form.values);
            // now the effect will react to every field change

            // skip the first execution (after reading the signals!)
            if (!first) onChange(formValues);
        }, true);
    }

    createEffect(() => {
        form.state.setReadOnly(!!props.readOnly);
        form.state.setOptionalityIndicator(props.optionalityIndicator);
        form.state.setFieldWrapperClass(props.fieldWrapperClass);
    });

    return (
        <FormStateContext.Provider value={form as unknown as FormController<FormValues>}>
            <SubmitWrapper {...props}>{props.children}</SubmitWrapper>
            {/* Children unmount in reverse order https://github.com/solidjs/solid/commit/8d0877e4a9589b065479784389ea2d163ec49ea1 */}
            <ThisWillUnmountFirst onCleanup={props.onCleanup} />
        </FormStateContext.Provider>
    );
}

function SubmitWrapper<TFormValues extends FormValues>(props: FormWrapperProps<TFormValues>) {
    const form = useFormState<TFormValues>();

    return (
        <form
            onSubmit={event => {
                event.preventDefault();
                /* Primary submit uses the same logic as secondary submit,
                 * but the method is called triggerSecondarySubmit so the use
                 * case outside the form core implementation is clearer. */
                form.triggerSecondarySubmit(props.onSubmit ?? (() => {}));
            }}
            class={props.class}
            style={props.style}
            noValidate // having our own validation components allows for better UX
        >
            {props.children}
        </form>
    );
}

function ThisWillUnmountFirst<TFormValues extends FormValues>(props: {
    onCleanup: FormWrapperProps<TFormValues>["onCleanup"];
}) {
    const form = useFormState<TFormValues>();
    onCleanup(() => props.onCleanup?.(_.clone(form.values)));
    return null;
}
