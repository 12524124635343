import { Accessor, createContext, ParentProps, Setter } from "solid-js";
import { Locale } from "../i18n/Locale";
import { useRequiredContext } from "../../utils/solidjs";
import { Role } from "../../api/services/auth/interface";

export const navigationRailWidth = "5.75rem";
export type RailTabKey = keyof Locale["menu"]["navigationRailTabs"];

const NavigationRailContext = createContext<RailContextValue>();

export type RailContextValue = ParentProps<{
    activeRailTab: Accessor<RailTabKey>;
    setActiveRailTab: Setter<RailTabKey>;
    roles: Accessor<Role[]>;
}>;

export default function NavigationRail(props: ParentProps<RailContextValue>) {
    return (
        <NavigationRailContext.Provider value={props}>
            <div class="flex flex-col items-center" style={{ width: navigationRailWidth }}>
                <div class="my-3 flex flex-col items-center gap-3">{props.children}</div>
            </div>
        </NavigationRailContext.Provider>
    );
}

NavigationRail.Tab = function (props: { key: RailTabKey; title: string; icon: string }) {
    const { activeRailTab, setActiveRailTab } = useRequiredContext(
        NavigationRailContext,
        "NavigationRail.Tab",
        "NavigationRail",
    );

    return (
        <button
            onClick={() => setActiveRailTab(props.key)}
            class="flex w-full flex-col items-center text-light-gray-700"
        >
            <div classList={{ "bg-primary-100 px-3 rounded-2xl": props.key === activeRailTab() }}>
                <i class={`text-md ${props.icon} leading-normal`} />
            </div>
            <div class="text-xs">{props.title}</div>
        </button>
    );
};
