import { assignHeaders, Client } from "../../modules/client/client";
import { parsedEnv } from "../../utils/parsedEnv";
import { isAuthenticated } from "../../modules/auth/authContext";
import { firebaseAuthService } from "../services/auth/implementations/firebase";

export const documentManagerClient = new Client(parsedEnv.VITE_SERVER_DOCUMENT_MANAGER, {
    intercept: async (request, fetchData) => {
        if (await isAuthenticated())
            assignHeaders(request.headers, {
                Authorization: `Bearer ${await firebaseAuthService.getIdToken()}`,
            });

        return await fetchData(request);
    },
});
