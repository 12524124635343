import { A } from "@solidjs/router";
import { createSignal, createUniqueId, mergeProps, ParentProps, Show } from "solid-js";

export default function TreeMenu(props: ParentProps) {
    return (
        <div
            class="grid grid-cols-3 items-baseline gap-y-1"
            style={{ "grid-template-columns": "auto auto 1fr" }}
        >
            {props.children}
        </div>
    );
}

TreeMenu.Tab = function (
    props: ParentProps<{
        href?: string;
        end?: boolean;
        icon?: string;
        title: string;
        size?: "lg" | "md" | "sm";
    }>,
) {
    const p = mergeProps({ size: "md" }, props);
    const loremIpsumId = createUniqueId();

    return (
        <>
            <Show when={p.children || p.size === "md"} fallback={<div />}>
                <i class={`fas fa-caret-right px-2 ${p.children ? "visible" : "invisible"}`} />
            </Show>
            <Show when={p.icon} fallback={<div />}>
                <div
                    classList={{
                        "h-text-lg w-text-lg": p.size === "lg",
                        "h-text-md w-text-md": p.size === "md",
                        "h-text-sm w-text-sm": p.size === "sm",
                    }}
                    class="center-items mr-1 px-3"
                >
                    <i class={p.icon} />
                </div>
            </Show>
            <A
                href={
                    p.href ??
                    `/lorem-ipsum/${props.title
                        .replaceAll(" ", "_")
                        .replaceAll("á", "a")
                        .replaceAll("é", "e")
                        .replaceAll("í", "i")
                        .replaceAll("ó", "o")
                        .replaceAll("ú", "u")
                        .replaceAll("ñ", "n")}/${loremIpsumId}`
                }
                end={p.end}
                classList={{
                    "text-md": p.size === "lg",
                    "text-default": p.size === "md",
                    "text-sm": p.size === "sm",
                }}
                class="flex items-center rounded-md py-1 text-light-gray-700"
                activeClass="bg-primary-100"
            >
                <div>{p.title}</div>
            </A>
            <Show when={p.children}>
                <div />
                <div />
                <TreeMenu>{p.children}</TreeMenu>
            </Show>
        </>
    );
};

TreeMenu.Separator = function () {
    return <div class="col-span-3 my-3 border-t border-t-light-gray-300" />;
};

TreeMenu.Label = function (props: { title: string; href?: string }) {
    const [isHovered, setIsHovered] = createSignal(false);

    return (
        <>
            <div />
            <div
                class="col-span-2 mt-1 flex items-center text-sm font-medium text-light-gray-500"
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                <div class="flex-1">{props.title}</div>
                <A
                    href={props.href ?? "/non-existent"}
                    class={`${
                        props.href && isHovered() ? "visible" : "invisible"
                    } mr-3 flex h-8 w-8 items-center justify-center`}
                    inactiveClass="text-light-gray-500"
                    activeClass="!visible"
                >
                    <i class="fas fa-cog" />
                </A>
            </div>
        </>
    );
};
