import { useLocale } from "../../i18n/context";
import NavigationRail, { RailContextValue } from "../NavigationRail";
import LoremIpsum from "../LoremIpsum";
import TreeMenu from "../TreeMenu";
import { menuTabs } from "../utils";
import { parsedEnv } from "../../../utils/parsedEnv";
import { mapValues } from "../../../utils/miniLodash";
import { JSX, Match, Show, Switch } from "solid-js";
import { Role } from "../../../api/services/auth/interface";

export function MockNavigationRail(props: RailContextValue) {
    const [locale] = useLocale();
    const t = () => locale().menu.navigationRailTabs;

    return (
        <NavigationRail {...props}>
            <NavigationRail.Tab key="home" title={t().home} icon="fas fa-home" />
            <NavigationRail.Tab key="tools" title={t().tools} icon="fas fa-cubes" />
            <NavigationRail.Tab
                key="organization"
                title={t().organization}
                icon="fas fa-user-shield"
            />
            <NavigationRail.Tab key="advanced" title={t().advanced} icon="fas fa-cog" />
            <NavigationRail.Tab key="myAccount" title={t().myAccount} icon="fas fa-user" />
        </NavigationRail>
    );
}

export function MockNavigationDrawer(props: RailContextValue) {
    const [locale] = useLocale();
    const items = useMenuItems();

    return (
        <Switch
            fallback={
                <LoremIpsum
                    variant="short"
                    title={locale().menu.navigationRailTabs[props.activeRailTab()]}
                />
            }
        >
            <Match when={props.activeRailTab() === "home"}>
                <nav class="py-3">
                    <TreeMenu>
                        {items.home()}
                        <TreeMenu.Separator />
                        <TreeMenu.Label
                            title="Alan"
                            href={
                                props.roles().includes(Role.ADMIN)
                                    ? "/checklist-administration"
                                    : undefined
                            }
                        />
                        {items.workflows()}
                        {items.activities()}
                        <TreeMenu.Tab {...menuTabs.checklists} title="Checklists" size="md">
                            <Show when={props.roles().includes(Role.ADMIN)}>
                                <TreeMenu.Tab
                                    {...menuTabs.checklistsAdministration}
                                    title="Administrar"
                                    size="sm"
                                    icon=""
                                />
                            </Show>
                            <TreeMenu.Tab {...menuTabs.checklists} title="Ver" size="sm" icon="" />
                        </TreeMenu.Tab>
                        <TreeMenu.Label title="Lucho" />
                        {items.organization()}
                        <Show when={parsedEnv.VITE_FEATURE_ORGANIZATION_CHART}>
                            {items.organizationChart()}
                        </Show>
                        {items.audiences()}
                        {items.posts()}
                    </TreeMenu>
                </nav>
            </Match>
        </Switch>
    );
}

export function useMenuItems(): Record<keyof typeof menuTabs | "empty", () => JSX.Element> {
    const [locale] = useLocale();
    const t = () => locale().menu.tabs;

    return {
        ...mapValues(menuTabs, ({ href, icon }, key) => {
            return () => <TreeMenu.Tab href={href} icon={icon} title={t()[key]} size="md" />;
        }),
        empty: () => <div />,
    };
}
