import { createSignal, JSX, mergeProps, ParentProps, Show } from "solid-js";
import { useNavigate } from "@solidjs/router";
import { OnClickButton, useThrowToErrorBoundary } from "../../utils/solidjs";
import aimLogo from "../../assets/imgs/new_logo.svg";
import { useLocale } from "../i18n/context";

export function Badge(props: ParentProps<{ class?: string }>) {
    return (
        <div class={`rounded-full bg-light-gray-100 px-2 py-1 text-xs ${props.class ?? ""}`}>
            {props.children}
        </div>
    );
}

type AsyncOnClickButton = (event: Parameters<OnClickButton>[0]) => Promise<void>;
type AsyncableOnClickButton = OnClickButton | AsyncOnClickButton;

export type GargamelButtonProps = Omit<FigmaButtonProps, "onClick"> & {
    /** `onClick` can be async. In that case, the button will show a spinner
     * and will be disabled while the promise is pending. */
    onClick?: AsyncableOnClickButton;
    /** Overrides the pending status of `onClick`.
     * Example: `pending={someMutation.isPending}`. */
    pending?: boolean;
    /** If provided, the button text changes to this when `onClick` is pending. */
    pendingText?: string | JSX.Element;
    /** A message that explains why a button is disabled.
     *
     * Shown as a tooltip only when the button is disabled.
     */
    disabledReason?: string;
    classList?: {
        [k: string]: boolean | undefined;
    };
};

export function Button(props: GargamelButtonProps) {
    const [onClickPending, setOnClickPending] = createSignal(false);
    const pending = () => props.pending ?? onClickPending();
    const throwToErrorBoundary = useThrowToErrorBoundary();

    /** Takes an `onClick` that may be sync or async, and converts it to an
     * `onClick` that is always sync. This new `onClick` has two new
     * responsibilities:
     * - Update the `pending` status.
     * - Throw errors to the nearest error boundary.
     */
    function toSync(asyncableOnClick: AsyncableOnClickButton): OnClickButton {
        return event => {
            try {
                const onClickReturnValue = asyncableOnClick(event);
                // noinspection SuspiciousTypeOfGuard [1]
                if (onClickReturnValue instanceof Promise) {
                    // Handle async onClick
                    setOnClickPending(true);
                    onClickReturnValue
                        .catch(throwToErrorBoundary)
                        .finally(() => setOnClickPending(false));
                }
            } catch (error) {
                // Handle sync onClick
                throwToErrorBoundary(error);
            }
        };
    }

    return (
        <DisabledReasonWrapper {...props}>
            <FigmaButton
                {...props}
                icon={pending() ? "fas fa-spinner fa-spin" : props.icon}
                disabled={pending() || props.disabled}
                onClick={props.onClick ? toSync(props.onClick) : undefined}
            >
                <Show when={pending()} fallback={props.children}>
                    {props.pendingText ?? props.children}
                </Show>
            </FigmaButton>
        </DisabledReasonWrapper>
    );

    /* [1] There are edge cases like `onClick={() => setSignal(...)}`,
     *     where onClick is sync but returns something that is not a promise. */
}

/** The logic to show a tooltip on a disabled button with a disabledReason. */
function DisabledReasonWrapper(
    props: ParentProps<{ disabled?: boolean; disabledReason?: string }>,
) {
    return (
        <Show when={props.disabledReason && props.disabled} fallback={props.children}>
            {/* We need an extra div as HTML doesn't show tooltips on disabled buttons */}
            <div title={props.disabledReason}>{props.children}</div>
        </Show>
    );
}

export type FigmaButtonProps = ParentProps<{
    onClick?: OnClickButton;
    variant?: "primary" | "danger";
    /** The `style` property of the Button component in Figma.
     * It was renamed to `bgStyle` to avoid clashing with the `style` prop for additional CSS Properties. */
    bgStyle?: "outline" | "solid" | "text-only";
    size?: "sm" | "md" | "lg";
    icon?: string;
    type?: JSX.IntrinsicElements["button"]["type"];
    class?: string;
    style?: JSX.CSSProperties;
    disabled?: boolean;
    classList?: {
        [k: string]: boolean | undefined;
    };
}>;

/** https://www.figma.com/file/v5xYVSXVVmwlgd70mgZu2Q/AIM---Front-refactor?type=design&node-id=46-449&mode=design&t=iyx0FIXbjFU93QpL-4 */
export function FigmaButton(props: FigmaButtonProps) {
    const merged = mergeProps({ variant: "primary", bgStyle: "solid", size: "md" }, props);

    return (
        <button
            class={`center-items max-w-full rounded-xs disabled:cursor-not-allowed ${props.class}`}
            classList={{
                "bg-primary-500 text-white disabled:bg-dark-gray-500":
                    merged.variant === "primary" && merged.bgStyle === "solid",
                "text-primary-500 disabled:text-dark-gray-400":
                    merged.variant === "primary" && merged.bgStyle !== "solid",
                "border-primary-500 disabled:border-dark-gray-400":
                    merged.variant === "primary" && merged.bgStyle === "outline",

                "bg-error-500 text-white":
                    merged.variant === "danger" && merged.bgStyle === "solid",
                "text-error-500": merged.variant === "danger" && merged.bgStyle !== "solid",
                "border-error-500": merged.variant === "danger" && merged.bgStyle === "outline",

                "text-sm px-2 py-1 gap-1": merged.size === "sm",
                "text-default px-4 py-2 gap-2": merged.size === "md",
                "text-md px-8 py-2 gap-2": merged.size === "lg",
                ...props.classList,
            }}
            style={{
                "font-weight": 500,
                "border-width": merged.bgStyle === "outline" ? "1px" : "0",
                "border-style": "solid",
                ...props.style,
            }}
            type={props.type}
            onClick={e => props.onClick?.(e)}
            disabled={props.disabled}
        >
            <Show when={props.icon}>{name => <Icon name={name()} />}</Show>
            {props.children}
        </button>
    );
}

export function Icon(props: { name: string; class?: string }) {
    return (
        <span class="center-items" style={{ width: "1rem", height: "1rem" }}>
            <i class={props.name} />
        </span>
    );
}

export function NavbarLogoOnly(props: { reload?: boolean }) {
    return (
        <div class="sticky left-0 right-0 top-0 z-navbar bg-white px-4 py-2 shadow-light-gray-200">
            <AimLogo reload={props.reload} />
        </div>
    );
}

export function AimLogo(props: { reload?: boolean; class?: string }) {
    const [locale] = useLocale();
    const navigate = useNavigate();

    function goHome() {
        if (props.reload) {
            location.href = "/";
        } else {
            navigate("/");
        }
    }

    return (
        <img
            src={aimLogo}
            alt=""
            onClick={goHome}
            aria-label={locale().utils.unknownError.goHome}
            role="button"
            class={`block h-16 py-1 sm:h-16 ${props.class ?? ""}`}
        />
    );
}
